import PropTypes from 'prop-types'
import invoice from '../../assets/images/logo-full.png'

const LoaderBox = ({ loader, noData, style }) => (
  <>
    {loader && (
      <div className="loading-content" style={style}>
        <div className="content">
          <img src={invoice} alt="Sowtastic" className="mb-2" />
          <h3>{loader === true ? 'Loading..' : loader}</h3>
        </div>
      </div>
    )}
    {!loader && noData && (
      <div className="loading-content" style={style}>
        <div className="content">
          <img src={invoice} alt="Sowtastic" className="mb-2" />
          <h3>{noData === true ? 'No Data found..' : noData}</h3>
        </div>
      </div>
    )}
  </>
)

LoaderBox.propTypes = {
  loader: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  noData: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  style: PropTypes.object
}

export default LoaderBox
